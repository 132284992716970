@import '../../styles/variables.scss';

#note-detail-modal {
  min-width: 60rem;

  .related-entity-info-box {
    position: absolute;
    left: -27rem;
    top: -8.6rem;
    padding: 2.8rem 3rem 2.3rem 2rem;
    background-color: #e4e4ea;
    width: 25rem;
  }

  a[href*='/unsubscribe.html'],
  a[title*='Unsubscribe'] {
    display: none;
  }
}

#note-form {
  .checkbox-container {
    font-family: "Biotif", sans-serif;
    padding-left: 30px !important;
    margin-left: 0.5rem !important;
    margin-top: 22px;
    font-size: 16px;
  }
}

.toast-response-background {
  background: #2dcaff !important;
  border-radius: 8px !important;
  text-align: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3) !important;
}

.toast-response-body {
  font-size: 16px;
  font-family: Biotif-SemiBold, Biotif, serif;
  color: #fff;
  margin: auto 0;
  flex: 1 1;
}
